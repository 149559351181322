<template>
    <psi-dialog title="Stripe Test Card Numbers" @close="$emit('close')">
        <v-list dense>
            <v-list-item v-for="(card, index) in stripeCards" :key="index">
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="text-h5">{{ card.number }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ card.description }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </psi-dialog>
</template>
<script>
import stripeCards from "../../data/_stripe-test-cards.json";

export default {
    name: "applicant-checkout-test-numbers",
    components: {},
    props: {},
    data() {
        return {
            stripeCards,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>