var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: { title: "Stripe Test Card Numbers" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.stripeCards, function(card, index) {
          return _c(
            "v-list-item",
            { key: index },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(card.number))
                    ])
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(card.description) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }